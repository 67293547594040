import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_ioBroker_Tutorials/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "ioBroker v9.1.2 as MQTTv5 Client for your  WQHD INSTAR IP Camera",
  "path": "/Frequently_Asked_Question/ioBroker_INSTAR_WQHD_MQTTv5_CLient/",
  "dateChanged": "2024-05-07",
  "author": "Mike Polinowski",
  "excerpt": "I have been trying to use the 2K cameras with the IoBroker for days now. The cameras all appear with its tree in the IoBroker, but unfortunately all variables are always null. If I want to set a value, I can, but it does not arrive in the camera.",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "WQHD & UHD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='ioBroker v9.1.2 as MQTTv5 Client for your  WQHD INSTAR IP Camera' dateChanged='2024-05-07' author='Mike Polinowski' tag='INSTAR IP Camera' description='I have been trying to use the 2K cameras with the IoBroker for days now. The cameras all appear with its tree in the IoBroker, but unfortunately all variables are always null. If I want to set a value, I can, but it does not arrive in the camera.' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/ioBroker_INSTAR_WQHD_MQTTv5_CLient/' locationFR='/fr/Frequently_Asked_Question/ioBroker_INSTAR_WQHD_MQTTv5_CLient/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    
    <h1 {...{
      "id": "iobroker-v912-as-mqttv5-client-for-your--wqhd-instar-ip-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#iobroker-v912-as-mqttv5-client-for-your--wqhd-instar-ip-camera",
        "aria-label": "iobroker v912 as mqttv5 client for your  wqhd instar ip camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ioBroker v9.1.2 as MQTTv5 Client for your  WQHD INSTAR IP Camera`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: I have been trying to use the 2K cameras with the IoBroker for days now. The cameras all appear with its tree in the IoBroker, but unfortunately all variables are always null. If I want to set a value, I can, but it does not arrive in the camera. `}</p>
    <p><strong parentName="p">{`A`}</strong>{`: We have seen issues with the MQTT Broker adapter in ioBroker before. It sometimes stops updating topics. With our new 2K+ WQHD cameras we now added the option to lower the QoS (Quality-of-Service) for the INSTAR MQTT client which seemed to be the issue. But right now I was able to reproduce the problem again. If this happens we recommend to first connect the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Windows_macOS_and_LINUX_Apps/"
      }}>{`MQTT Explorer`}</a>{` and see if the updates from you camera are actually published. In my case just now I had to switch the `}<strong parentName="p">{`MQTT Broker/Client`}</strong>{` adapter to `}<strong parentName="p">{`Client Mode`}</strong>{`, activate the broker inside my camera and connect ioBroker and the MQTT explorer with the broker before I was able to see updates in the MQTT Explorer - but there was still nothing in the ioBroker object tree.`}</p>
    <p>{`If this is the case - just switch to the trusty `}<strong parentName="p">{`MQTT Client`}</strong>{` adapter instead. This one never acted up for me:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0674aa7368dab773da3251d0a6b32f45/0d98f/ioBroker_MQTT_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30.869565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsTAAALEwEAmpwYAAABhUlEQVQY0yWMW2vTYABA8wdknWvapbHNrU2a5lvaXL5kTdJM1oplUwdOEaGOXRSrsDlxD4MJgvi/j9A9nJfD4Si2McJsbGPvNHlRPWdR1iwPDqliyXSSUMl99mNJPonJo+SRWCLjjGSSPBKnpKlEJhnK64u/LM//Ub76zizKWEznzKZzZFIhk5KD6pAir0nljCyvkdM5k6ggSwuKrCBPc+qyoi5LhL+H8u7yD8erB6qXF1ShZP32jpPVPYuPvzhd/+bo/I7Vh1uOz+6p31/z5usDy08/OTpdc3V1zbfLz9ze3HD25QfCG6EUA49Q6+A3W9RhSh5ERF5A5AoCb4xj7yGGAbbr0tV7tDUTywkRAx+nZ9NpttF1G3MwRrg+yjSImdg+otffDDM3QJg21o5Gd1vFVDV8z2fguWjtDo0nW3R3O5hdg1ZLRW2oNLeeojfbiKFAkcMxoekRdB1mYUolYmYiYaRbOJpB33AYOC6+O0LXntFWNUzDxjJs+lZ/43ZbGpbhbJr/WirAMjAFdGMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0674aa7368dab773da3251d0a6b32f45/e4706/ioBroker_MQTT_01.avif 230w", "/en/static/0674aa7368dab773da3251d0a6b32f45/d1af7/ioBroker_MQTT_01.avif 460w", "/en/static/0674aa7368dab773da3251d0a6b32f45/7f308/ioBroker_MQTT_01.avif 920w", "/en/static/0674aa7368dab773da3251d0a6b32f45/8faeb/ioBroker_MQTT_01.avif 1276w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0674aa7368dab773da3251d0a6b32f45/a0b58/ioBroker_MQTT_01.webp 230w", "/en/static/0674aa7368dab773da3251d0a6b32f45/bc10c/ioBroker_MQTT_01.webp 460w", "/en/static/0674aa7368dab773da3251d0a6b32f45/966d8/ioBroker_MQTT_01.webp 920w", "/en/static/0674aa7368dab773da3251d0a6b32f45/63399/ioBroker_MQTT_01.webp 1276w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0674aa7368dab773da3251d0a6b32f45/81c8e/ioBroker_MQTT_01.png 230w", "/en/static/0674aa7368dab773da3251d0a6b32f45/08a84/ioBroker_MQTT_01.png 460w", "/en/static/0674aa7368dab773da3251d0a6b32f45/c0255/ioBroker_MQTT_01.png 920w", "/en/static/0674aa7368dab773da3251d0a6b32f45/0d98f/ioBroker_MQTT_01.png 1276w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0674aa7368dab773da3251d0a6b32f45/c0255/ioBroker_MQTT_01.png",
              "alt": "ioBroker v9.1.2 as MQTTv5 Client for your  WQHD INSTAR IP Camera",
              "title": "ioBroker v9.1.2 as MQTTv5 Client for your  WQHD INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now activate the `}<strong parentName="p">{`Broker Mode`}</strong>{` inside your camera - Note that you cannot use TLSv1.3 encryption (v1.2 works fine) and that you should reduce the QoS to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "913px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/df9b72858d19fef7f31bba1dc8cf2769/0eb6d/ioBroker_MQTT_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "107.3913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAAAsTAAALEwEAmpwYAAADvklEQVQ4y12U2Y7iRhSGuUimF9yAF7Bd5Q2Mwdh4YRpIz9KTobtn0SjKXCWRouQBorzGRLnMfV72i6oMrelc/CqrwF/9/znH1TvcPXDz8hWbzXOKYsV6XdG2LVVVsV6vKddriqLoVJZ6LctSa13V1E3DulRrS7Va02vqmrLZUrUbtptSw9TebrvlxYsbNm1Lscopi5U+sFitaJqatqko6w3Nbkdb72ivv2OzuaYngxAppZYfRFoyjPFEgOtLvfoy/J8CHNenrFvquiEIJEEQkGUZPWc8xrQcBld95u//pPj1H6afvxB9/otYSz1/IfrxqONz+cvf3P7xL9XtZ9yxjZSBNtUzbQfTHjOyLOTqhtn2I/HmAVkfkNVR9YGgvuvUqPVA8vwd8fYT1e41RVmQryvSbEHPGrsd0B7jeS5FnlGuMqZxQBQK4kgSCI/J2P5KFq7vM57W3L55zd3DA/umYbnM6VlOB1Sa+AIZxYgwJkkz0kVOuljqPVXLIIoJogQRRHgyYiyn7PZbPrx7S5vPSKKQnulMjpFtxq7XQYVHPo8p81RrGvl4QhDGMXGSEIQRri8Yuy7X22vefP+G2/uP5GX1FdB28IRkkefM0lRH8nzBxPM0LEqmTNO5dqm6LMNIu97u97w9HMiyOTKQTx2qU6dpSjydaUAYJ/qQbn/OfLEkmaX6N/UfFT1bLmg3LVEUEUaR6rKqn8PQ7AqdzGbEyRRfdqATUEFkFGl3Igi11JyuihW7/TV5UeL7gp418R4jqxcVULlU0Z4Ck6Or8FEqti981CyPJxNcz6OnoqrROQHjaRdVAU6w00Fq3z/CTutwZHJ5ecnF5SWmZdEbmSbK5chydBNUnRbLnHmWIYLgEajdyEA3SMk/OhyZFn3D0Bp1QAvT6Qb75ES5VLFPQOW0k8CPM/xopmssNNDUsAujj2c69JRl1WnlUL08m2ek8wwZhk9g+llJD3V4dCq1Q8MwODP6TE23A+oaWnYXOVPAFF887XKYTPVXorusFaJuqhPw3OiTKODps9NfiuczCWc4IsFyu4hqNBRQXVFSuLjeBEtKRkLgyOCpQ8s9DbbDYGQRCJdDafOhsmjnExzvBBWIfE/Y3hMVL3gdJrxPUvZhzMA0MYw+Z/1LZtaki6wKezU0ieWEn7Ymv9+MeLm0sV2JfwSGzR2zVz+TtO/5IUn5LSu4j1MGtsPVYMT51YDU8Trgo0yLs4HDt1cOFwOT4XDI4Kgr4wLj4hlG/4xnwxHfDEecmxZDdTmbNufDEdlY8B+Zfz1xBVRTHQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/df9b72858d19fef7f31bba1dc8cf2769/e4706/ioBroker_MQTT_02.avif 230w", "/en/static/df9b72858d19fef7f31bba1dc8cf2769/d1af7/ioBroker_MQTT_02.avif 460w", "/en/static/df9b72858d19fef7f31bba1dc8cf2769/90e2f/ioBroker_MQTT_02.avif 913w"],
              "sizes": "(max-width: 913px) 100vw, 913px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/df9b72858d19fef7f31bba1dc8cf2769/a0b58/ioBroker_MQTT_02.webp 230w", "/en/static/df9b72858d19fef7f31bba1dc8cf2769/bc10c/ioBroker_MQTT_02.webp 460w", "/en/static/df9b72858d19fef7f31bba1dc8cf2769/5ffe7/ioBroker_MQTT_02.webp 913w"],
              "sizes": "(max-width: 913px) 100vw, 913px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/df9b72858d19fef7f31bba1dc8cf2769/81c8e/ioBroker_MQTT_02.png 230w", "/en/static/df9b72858d19fef7f31bba1dc8cf2769/08a84/ioBroker_MQTT_02.png 460w", "/en/static/df9b72858d19fef7f31bba1dc8cf2769/0eb6d/ioBroker_MQTT_02.png 913w"],
              "sizes": "(max-width: 913px) 100vw, 913px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/df9b72858d19fef7f31bba1dc8cf2769/0eb6d/ioBroker_MQTT_02.png",
              "alt": "ioBroker v9.1.2 as MQTTv5 Client for your  WQHD INSTAR IP Camera",
              "title": "ioBroker v9.1.2 as MQTTv5 Client for your  WQHD INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Add the camera IP and your configured broker login to the MQTT Client adapter configuration:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/35b2306f9030673cb4c2c54ea0caa1e9/3f20e/ioBroker_MQTT_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAACVElEQVQ4y22Ty27bOBSG9QrJJI1zLZzYongTKVKSpdhOnEw9hYumBWaK2cyqQIFu2kWB2cyrtA/7DUSnbdJ28YMiyPOd8x8eZdOi5+JozPT0nPXihs1qzd16w6pbsGwvWbSXzNuey7ZP69fv/gd1bc+s7cnC2VP0wYiLnV1e/f6CzfUz1vMV68WK69mcLjQsZz2XsaH1kbaqkxpfU/+g6CPZP/9+5q9PX7j9+z/evX7LTZwTC0NUjiAtlTQEGwnxCqc9ThqctJT3sg9kCkP28s1Hbu/e0y3/ZOUarlzD3EY6G+jKSK0sob6mev0B7XuskDhZPoKWD4Ht+QQzOkLuj/hjtmDTX7OqZvQ20A9A7ZHTAjHJyScCMS3QQmGFRucKI/QjZTNV4c4l+mzC8+4qAZcm0ghLyDUmVxS5REwlWhqkUAmqhKKyVTqTufqmbBE63LhAn064u3q2BdpIqzzexmRFFzqBXFnhrEcVGqMslQvkU5GghZBpzTb1Ench0ScX3NQ9t+2cKEtMLrHVnFI55FCFUElVGYhVQ/B1qnhI8LXyIVFWn+Wo03PE4VPmVUtQjlJobGHQucQZhxIadW+peGBPFYZSO3Rh0p0Bmo33Dhn/NmK8f0zna5oypBccGqwH0JBZbAFGmhScqklnegu/3yfg2e4BxztPONk7ZOZrKu0oC4Mtvo/CFqTx1uPLKgUPNh/KpF4bspPdA4529jnZG9GHllnVbIHfBvb7ZW8roovb/U/AbdLsdPdJAh7vHdCFNv1qaaaKxxrse+NpQpP6OgT/Sv8DfhaQ8Qhq7cYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/35b2306f9030673cb4c2c54ea0caa1e9/e4706/ioBroker_MQTT_03.avif 230w", "/en/static/35b2306f9030673cb4c2c54ea0caa1e9/d1af7/ioBroker_MQTT_03.avif 460w", "/en/static/35b2306f9030673cb4c2c54ea0caa1e9/7f308/ioBroker_MQTT_03.avif 920w", "/en/static/35b2306f9030673cb4c2c54ea0caa1e9/441b2/ioBroker_MQTT_03.avif 1275w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/35b2306f9030673cb4c2c54ea0caa1e9/a0b58/ioBroker_MQTT_03.webp 230w", "/en/static/35b2306f9030673cb4c2c54ea0caa1e9/bc10c/ioBroker_MQTT_03.webp 460w", "/en/static/35b2306f9030673cb4c2c54ea0caa1e9/966d8/ioBroker_MQTT_03.webp 920w", "/en/static/35b2306f9030673cb4c2c54ea0caa1e9/5e05a/ioBroker_MQTT_03.webp 1275w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/35b2306f9030673cb4c2c54ea0caa1e9/81c8e/ioBroker_MQTT_03.png 230w", "/en/static/35b2306f9030673cb4c2c54ea0caa1e9/08a84/ioBroker_MQTT_03.png 460w", "/en/static/35b2306f9030673cb4c2c54ea0caa1e9/c0255/ioBroker_MQTT_03.png 920w", "/en/static/35b2306f9030673cb4c2c54ea0caa1e9/3f20e/ioBroker_MQTT_03.png 1275w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/35b2306f9030673cb4c2c54ea0caa1e9/c0255/ioBroker_MQTT_03.png",
              "alt": "ioBroker v9.1.2 as MQTTv5 Client for your  WQHD INSTAR IP Camera",
              "title": "ioBroker v9.1.2 as MQTTv5 Client for your  WQHD INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Note that I limit the subscriptions to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`prefix/cameraID/status/multimedia/#`}</code>{` and  `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`prefix/cameraID/multimedia/#`}</code>{` - those are wildcard subscriptions to `}<strong parentName="p">{`all`}</strong>{` multimedia status and command topics - adjust this according to your needs by adding more topics comma seperated:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/262f3a6a727107a6ddbfc53557ddd8e0/3f20e/ioBroker_MQTT_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAABt0lEQVQoz22RzW7TQBRG/QAgtaFJSJu4QSL+mxnbYzuxHZKmBYkKiX1F+yA8BCzYoQSJFStUwTtQJDYgRPpAB83EhCy6OLqaq5lz59N1JmGCGnood8SL6YLzcs5cZlRRShYqVCgJ/YjAC5GRIpYJUSBs7y6c08kMeeyhjj1ezp9xXp1QCyOLUYFEBMJiLisRk+vC1sAPrXgXK3wqCuTQQ7qPeV4vOCtnTOLcyqQVboh8QSxTcj0mVdpyp1AcDvEfung9l3lRMVYZsZH5AuELK9oQ2bg6yVBRjAzVZpBFbIc6jw56uPtdhp0jpnlJoTKSUJGKhCSKiRtMbCPK08LKzGOxk0A0Yqff6tLba+N2jpiNa06qGWVWMtYTxnpM0aCsVP4XmR/uIJvqDFpdDvfbDNo9skihI4XyQqKRTzQKtgizZRM7lBZhluIFW0RTnX6rQ2/vgEH3iLpcMK1PKYonaF2hs4rUoCsSc86nTKoFZbVAZ7Xt/SPWNXFa4fQfdOjcu8/I17z7/IPl9TfeX9+w+vKdj19veP3pJxfLW65Way5Xa14t/1gum/PVh1sulmvmb35z9vYXfwHJ0SXAN2UV6AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/262f3a6a727107a6ddbfc53557ddd8e0/e4706/ioBroker_MQTT_04.avif 230w", "/en/static/262f3a6a727107a6ddbfc53557ddd8e0/d1af7/ioBroker_MQTT_04.avif 460w", "/en/static/262f3a6a727107a6ddbfc53557ddd8e0/7f308/ioBroker_MQTT_04.avif 920w", "/en/static/262f3a6a727107a6ddbfc53557ddd8e0/441b2/ioBroker_MQTT_04.avif 1275w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/262f3a6a727107a6ddbfc53557ddd8e0/a0b58/ioBroker_MQTT_04.webp 230w", "/en/static/262f3a6a727107a6ddbfc53557ddd8e0/bc10c/ioBroker_MQTT_04.webp 460w", "/en/static/262f3a6a727107a6ddbfc53557ddd8e0/966d8/ioBroker_MQTT_04.webp 920w", "/en/static/262f3a6a727107a6ddbfc53557ddd8e0/5e05a/ioBroker_MQTT_04.webp 1275w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/262f3a6a727107a6ddbfc53557ddd8e0/81c8e/ioBroker_MQTT_04.png 230w", "/en/static/262f3a6a727107a6ddbfc53557ddd8e0/08a84/ioBroker_MQTT_04.png 460w", "/en/static/262f3a6a727107a6ddbfc53557ddd8e0/c0255/ioBroker_MQTT_04.png 920w", "/en/static/262f3a6a727107a6ddbfc53557ddd8e0/3f20e/ioBroker_MQTT_04.png 1275w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/262f3a6a727107a6ddbfc53557ddd8e0/c0255/ioBroker_MQTT_04.png",
              "alt": "ioBroker v9.1.2 as MQTTv5 Client for your  WQHD INSTAR IP Camera",
              "title": "ioBroker v9.1.2 as MQTTv5 Client for your  WQHD INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If everything is set up correctly the client should now be connected:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ba4d94f050931ee99e8cb42c182a2b15/3f20e/ioBroker_MQTT_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "42.173913043478265%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAABqElEQVQoz1XOy27TQBiGYV8FIlVF26SJZ8Zjz3jGY8enOm6VQ9ugdgFIbGDBTbBgx7UgLvNFdUsRi0f/cfFFsaxZnJyzOp2zbQZ27cjtZksfahpXsXblpPbV5Kmv/vIlpfsnuJKoOJ+jZyfEb97SlWsqV7B2HpemVN5R+QJnDGmSYNKU4D1VCBTeY62d5Hk+sXlO9PXHLz59/832208ejgMPh4H6UGB6xfrgGR86yr0n3SQkvSDsPP37Fnudoa4kyaBQL5IhITrcf6HffCTUO467huNYM+xrXJPgh4xyk+MGi9lYltWKfMy5uuvJWo2q5X+SJiEqFyv07BQ5m7E/juzvR7rR4TtNGC1hdKguQbSSRbVEdor8Oke3CtUIZCMQ9VOVqEYSVSrHXEjU2YL73Q2fHx+56WoKoym8wZWWOBPERkxVWoV2KdJIxMtswvOPyCRR7xuy8xj1bsFh3PBhvGPrWhodyJYaMRfIS/VKLCSreYy4lM+7pULH6estunUt2YVAn10y9Gu6uqSqHJnTmGCwwaKsQhg5pdA+Ja/clGxKbQSrLH7t/wAFPP/BMbf52gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ba4d94f050931ee99e8cb42c182a2b15/e4706/ioBroker_MQTT_05.avif 230w", "/en/static/ba4d94f050931ee99e8cb42c182a2b15/d1af7/ioBroker_MQTT_05.avif 460w", "/en/static/ba4d94f050931ee99e8cb42c182a2b15/7f308/ioBroker_MQTT_05.avif 920w", "/en/static/ba4d94f050931ee99e8cb42c182a2b15/441b2/ioBroker_MQTT_05.avif 1275w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ba4d94f050931ee99e8cb42c182a2b15/a0b58/ioBroker_MQTT_05.webp 230w", "/en/static/ba4d94f050931ee99e8cb42c182a2b15/bc10c/ioBroker_MQTT_05.webp 460w", "/en/static/ba4d94f050931ee99e8cb42c182a2b15/966d8/ioBroker_MQTT_05.webp 920w", "/en/static/ba4d94f050931ee99e8cb42c182a2b15/5e05a/ioBroker_MQTT_05.webp 1275w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ba4d94f050931ee99e8cb42c182a2b15/81c8e/ioBroker_MQTT_05.png 230w", "/en/static/ba4d94f050931ee99e8cb42c182a2b15/08a84/ioBroker_MQTT_05.png 460w", "/en/static/ba4d94f050931ee99e8cb42c182a2b15/c0255/ioBroker_MQTT_05.png 920w", "/en/static/ba4d94f050931ee99e8cb42c182a2b15/3f20e/ioBroker_MQTT_05.png 1275w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ba4d94f050931ee99e8cb42c182a2b15/c0255/ioBroker_MQTT_05.png",
              "alt": "ioBroker v9.1.2 as MQTTv5 Client for your  WQHD INSTAR IP Camera",
              "title": "ioBroker v9.1.2 as MQTTv5 Client for your  WQHD INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The camera should automatically update all status topics. If they don't appear you can try to restart your camera or simply update something by switching something through the camera webUI:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d9387e2e530d8e263c8b04337d5c61ab/0d98f/ioBroker_MQTT_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.17391304347827%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAACoUlEQVQ4y32TzXLbNhRG9QpJ5MSSrUgyKYoACAIUCZIS9WNZspu2btpu0lT2ojOZdNuZLrrso+RVT4eQnDbTaRdncAkOD78LXnbsRJMMJ0T9IXVa0BQNpa1YFAucKZglFmdzssSQCu0xUpNKjRYJWmry9n6aUcwcnY3SFKMr4pd9tuWaa9fgdM4im3O3vKWyjtI6CpN7rLKnusAog8tKrM4oMoebVXQOf3zix98/sTv8yd4tucsrXBhRx4qv51tsGJIGISoIPDporyd+T4dH5PiKuc0w05jO3be/sL59oJy/ZXf/K9+/+427+w/U+0fc7kB5+4jZHUh270lu3qN3P5PtD+T7R/L9A9n+gXx/YPPDR/LdT3Ty8QTVu0CcX3K9uuebNw+8ffOOpv6KsrrFuh2quEYW11+s2m3R7obEbUmKDfPtd9hyS6fJakwgUa9D1olhX225X2woI0kaTpHhBBGExP+BCCdE4ysKm6F8y25FOo5Rg5C1cWyqG/bNDVZoZCQRkUROFeJfSOKpZBoJojDG5SWJ1HRWQYIeRcT9EU4aZtKynm/8mGih/Rc8SqWXHFGfX5RnjlSlFHlNklg6426PoNtjfHaBiRSZSFlXjZ+33ORUeUU8EUfhKbE41YnQNPUSFSvKckVqHJ3Ri3Mun7/ksntO2gqVoSkXiImgsAXlrEREwqdppUeOLSuReGE73GV1Eray/rMzLrrn6Eh64bJq/Pk9CdtaxYlP8kQrbs9sUS18XVUrTCscfBa+IokkVqZfJKzyY0Ivmv6NF7Ytz5ckQvmE5pjwjN6zLv0Xr3xCIw2LcoGOFZm25HbmH/5nuie0TKiKyv/Xdb3EmoLOxUnYe35GMBgSjCLyrEYEEaPLIePBiPHr/2c0GKJiSTgO+AtkG7ExObWAgQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d9387e2e530d8e263c8b04337d5c61ab/e4706/ioBroker_MQTT_06.avif 230w", "/en/static/d9387e2e530d8e263c8b04337d5c61ab/d1af7/ioBroker_MQTT_06.avif 460w", "/en/static/d9387e2e530d8e263c8b04337d5c61ab/7f308/ioBroker_MQTT_06.avif 920w", "/en/static/d9387e2e530d8e263c8b04337d5c61ab/8faeb/ioBroker_MQTT_06.avif 1276w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d9387e2e530d8e263c8b04337d5c61ab/a0b58/ioBroker_MQTT_06.webp 230w", "/en/static/d9387e2e530d8e263c8b04337d5c61ab/bc10c/ioBroker_MQTT_06.webp 460w", "/en/static/d9387e2e530d8e263c8b04337d5c61ab/966d8/ioBroker_MQTT_06.webp 920w", "/en/static/d9387e2e530d8e263c8b04337d5c61ab/63399/ioBroker_MQTT_06.webp 1276w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d9387e2e530d8e263c8b04337d5c61ab/81c8e/ioBroker_MQTT_06.png 230w", "/en/static/d9387e2e530d8e263c8b04337d5c61ab/08a84/ioBroker_MQTT_06.png 460w", "/en/static/d9387e2e530d8e263c8b04337d5c61ab/c0255/ioBroker_MQTT_06.png 920w", "/en/static/d9387e2e530d8e263c8b04337d5c61ab/0d98f/ioBroker_MQTT_06.png 1276w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d9387e2e530d8e263c8b04337d5c61ab/c0255/ioBroker_MQTT_06.png",
              "alt": "ioBroker v9.1.2 as MQTTv5 Client for your  WQHD INSTAR IP Camera",
              "title": "ioBroker v9.1.2 as MQTTv5 Client for your  WQHD INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`I am now using the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Windows_macOS_and_LINUX_Apps/"
      }}>{`MQTT Explorer`}</a>{` to switch a value using the corresponding `}<strong parentName="p">{`command topic`}</strong>{` - e.g. switch on a privacy area to see the result in your camera's livestream immediately. You can also see that the moment you send the update to the command topic the status topic in MQTT Explorer should update as well:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/778c1c2f100e092f341e2ea1f92ca73f/51ed8/ioBroker_MQTT_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACJklEQVQoz2WS21LTUBhG8w5eOczQIj1wapumtM2pO2mS5tQ2h0Idp6OD3Hnhna8gguiVoAg+7HIShIF6sWbv+bP/lW8fJHt6izO7xc/+YMc3iPCGKL8jyO6YJLfPKL4Px1eozjWqc4XqXiOCX+jeT8L8N0dv75Cmr3+QrK4Zz85w5meMos/4+QV+doGbnD/hC35+iTX9yig6LxHxRVlzk0vmb75xfPIdKQwc5lMfQ+9jCQ1d7bG/V+dgv/FI66DJ3m6NYV8mmfl47ghLqNhCLceiT4x0TEND8rwJYRihaTpCWAyHKu12B7nbRZb/0e2WNU3XSdOM6XRWMpvNCIKQOPYJfBvHsZH6/T7L5RLXdbFtG9M0KWqdTgdZlh9pt9uoqkqapiRJWo5puuD09JR8+ZH50SfGboKkKAqr1Yr5PEFYFo7jlI2F4Kmw1Wqh6zqe52GYGoahMxiaLBYZ5vgd2vgDlpMiFYsXi5wo8rGEwWQyYTAYPAofkhZCwzDQjSH15iaN5ha1eoVKdYPt7ZdUKy9wXYFULEzSY5L8PZoRoaoaRer1LT8IzZFWypo7r9jZ3b5nr0a9sYXnOYXwAGF5pPkJjpejKL1n6dYTGuaQWn2TeqNKvVEpUxbz6tYGQThBKppN0yCOAizLpN1uPdvq+hnaY0G320JROvR6MoeH3RKl1yEI/Xth8WffDxiNxH+XsS4snklxca7jlucdxzFhEBKFEVmW8RfLE3/wWQy8UAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/778c1c2f100e092f341e2ea1f92ca73f/e4706/ioBroker_MQTT_07.avif 230w", "/en/static/778c1c2f100e092f341e2ea1f92ca73f/d1af7/ioBroker_MQTT_07.avif 460w", "/en/static/778c1c2f100e092f341e2ea1f92ca73f/7f308/ioBroker_MQTT_07.avif 920w", "/en/static/778c1c2f100e092f341e2ea1f92ca73f/14e47/ioBroker_MQTT_07.avif 1021w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/778c1c2f100e092f341e2ea1f92ca73f/a0b58/ioBroker_MQTT_07.webp 230w", "/en/static/778c1c2f100e092f341e2ea1f92ca73f/bc10c/ioBroker_MQTT_07.webp 460w", "/en/static/778c1c2f100e092f341e2ea1f92ca73f/966d8/ioBroker_MQTT_07.webp 920w", "/en/static/778c1c2f100e092f341e2ea1f92ca73f/11b7f/ioBroker_MQTT_07.webp 1021w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/778c1c2f100e092f341e2ea1f92ca73f/81c8e/ioBroker_MQTT_07.png 230w", "/en/static/778c1c2f100e092f341e2ea1f92ca73f/08a84/ioBroker_MQTT_07.png 460w", "/en/static/778c1c2f100e092f341e2ea1f92ca73f/c0255/ioBroker_MQTT_07.png 920w", "/en/static/778c1c2f100e092f341e2ea1f92ca73f/51ed8/ioBroker_MQTT_07.png 1021w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/778c1c2f100e092f341e2ea1f92ca73f/c0255/ioBroker_MQTT_07.png",
              "alt": "ioBroker v9.1.2 as MQTTv5 Client for your  WQHD INSTAR IP Camera",
              "title": "ioBroker v9.1.2 as MQTTv5 Client for your  WQHD INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Repeat the last step - but this time using the `}<strong parentName="p">{`RAW Command Topic`}</strong>{` that allows you to use the value directly as payload instead of having to use JSON:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6eb5e6dabcb9f119d4d97f68172610be/eb3fa/ioBroker_MQTT_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACF0lEQVQoz2WSy07bUBRF/REdVVUbShIIxI6dp9/P2PEjxE6AQiXUSu2gw0r9jJaQUGDQQb91VXYgCmWwdO/gnHX3uTqCMV5ihUvceI0d3WAGS8LpmnG2JkhXW7xkhR0tGdo/GTm/GDqb0xxfo3nXhNMbZucrhOTDH/Krv4TzW4JihZPdkJzfkZz9JlrcVoSPJOf32NNbrHSNna2re3R6T5DfkV89cPH1ASEaOxR5iq4NsC0VXe1zfNSkfXywRWwfctRqMBwoTLOQcWBVtY6tYpkqtqVhmRqmYSD4fkCapui6get6aJqGJEnIioIsP6IoSFIHTdM5OZmRphlJklZU9zgkiX2CwEfo9/ssFguCIMC2bQzDYDAY0Ol0kGV5S/mIqqrM8oKimFc9JVmWUZx94/TiB/44RyiLLy8/UsznVcJSrOv6JuWOUBRFdF3D8yM0zcQ0je3jhvsZI/iO4y02wnlRkGUJlqlXwtFotBU+Jd0IS4lKvfFuhxr1+mtqtVe4roUgiSJpllMsPqEZk6qp1+u9GPlJaFoazYM9Dlv7j7znsFWncbCH53sIotjGMD2K0y8E4Rm9/vBZut2E5YiGqbLfeEujWaPR3KQs73v7b3A9B6FsLv8mS+Mq8v+jPk+o47o2HbmNokgoXYlut0O310FWRMIw3AjLVUmSBMdxXoh2hWXdZDKhXLXyr6MwqtYmjuPqnM1y/gGRdH8KLRh9IAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6eb5e6dabcb9f119d4d97f68172610be/e4706/ioBroker_MQTT_08.avif 230w", "/en/static/6eb5e6dabcb9f119d4d97f68172610be/d1af7/ioBroker_MQTT_08.avif 460w", "/en/static/6eb5e6dabcb9f119d4d97f68172610be/7f308/ioBroker_MQTT_08.avif 920w", "/en/static/6eb5e6dabcb9f119d4d97f68172610be/b248f/ioBroker_MQTT_08.avif 1026w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6eb5e6dabcb9f119d4d97f68172610be/a0b58/ioBroker_MQTT_08.webp 230w", "/en/static/6eb5e6dabcb9f119d4d97f68172610be/bc10c/ioBroker_MQTT_08.webp 460w", "/en/static/6eb5e6dabcb9f119d4d97f68172610be/966d8/ioBroker_MQTT_08.webp 920w", "/en/static/6eb5e6dabcb9f119d4d97f68172610be/1dc52/ioBroker_MQTT_08.webp 1026w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6eb5e6dabcb9f119d4d97f68172610be/81c8e/ioBroker_MQTT_08.png 230w", "/en/static/6eb5e6dabcb9f119d4d97f68172610be/08a84/ioBroker_MQTT_08.png 460w", "/en/static/6eb5e6dabcb9f119d4d97f68172610be/c0255/ioBroker_MQTT_08.png 920w", "/en/static/6eb5e6dabcb9f119d4d97f68172610be/eb3fa/ioBroker_MQTT_08.png 1026w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6eb5e6dabcb9f119d4d97f68172610be/c0255/ioBroker_MQTT_08.png",
              "alt": "ioBroker v9.1.2 as MQTTv5 Client for your  WQHD INSTAR IP Camera",
              "title": "ioBroker v9.1.2 as MQTTv5 Client for your  WQHD INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Both command topics should now be shown in the ioBroker object tree - for some reason they initially showed `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`null`}</code>{` as value. Click on the settings icon to configure both of them:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/84fdaeb9e055e1ccae0089da60c179c7/a3a5c/ioBroker_MQTT_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACRklEQVQoz2XT3W7TSBjGcd8CH4JuWwpJ7Nie8Yy/x47ttDFtaKumRRW0iNJWgMSuVuICOCHq7tFqDxDXgcQ1/lGcKkJw8NMzoxk9B6N3LMctGGz0sNe3mDYtB5Mph+0zJuWYxlRUWUmVl4yygjI1t1lQpIYiKzCpoczLTpGXWNnAJXxsM3y4QREkVMmIVOWUSYmJMtIgwkQpcRAS+grlSUKh0EIReBItNYlOiBb3UoM1rac0cY22NXtly66pMTKiCg3Pd08wOiFTCamKO7EMSRd7nRCKkDRM0UJjEkMWZVhv5t84+/yd/Q9faE3LQdVSLi6IiEnWELsS5Qr8oY8/9JCuQLtyZXEmHb97mlBqrCoakXoR2ok4evsv5x+/cv73/+xd/8Pkcs7k6obR1Zzi8vNKdTVnfH3Tqa9vqC7n7P/1H+OLT1i5qwifOIjNAe3uOdOj95ye/sl2e8Zo8oJsckrQnPxGjU/Q4xPieoasZ1SHFyQ7z7HG6Yiw7yO3HNq91+wfvWN2/LYrM09fYvbOkPVxVyJ/KlysVXNMVM8Q1Yxi/xW6OcQ6SCoiWyI2+9RCsZM3tKZE2Q5+f4Dft29zgNe3bw2WBgs2w16PVGt8x8GKN+2ubLi2ReIGpFFFU26jvADpBogVib8SrAgvwHMEeVqgZIjVu/8HvXtrPL6/jnZ8Ym2oi3E3a2Ioltxl+gvuklilxBv65IlBCY316O5D1u88YOPeGmooCH3N9miHWMXdsC5ma1kqfyGQftD9jkhF5NkIrTN+AFoeZfXpXvyCAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/84fdaeb9e055e1ccae0089da60c179c7/e4706/ioBroker_MQTT_09.avif 230w", "/en/static/84fdaeb9e055e1ccae0089da60c179c7/d1af7/ioBroker_MQTT_09.avif 460w", "/en/static/84fdaeb9e055e1ccae0089da60c179c7/7f308/ioBroker_MQTT_09.avif 920w", "/en/static/84fdaeb9e055e1ccae0089da60c179c7/71423/ioBroker_MQTT_09.avif 1277w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/84fdaeb9e055e1ccae0089da60c179c7/a0b58/ioBroker_MQTT_09.webp 230w", "/en/static/84fdaeb9e055e1ccae0089da60c179c7/bc10c/ioBroker_MQTT_09.webp 460w", "/en/static/84fdaeb9e055e1ccae0089da60c179c7/966d8/ioBroker_MQTT_09.webp 920w", "/en/static/84fdaeb9e055e1ccae0089da60c179c7/4d3e0/ioBroker_MQTT_09.webp 1277w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/84fdaeb9e055e1ccae0089da60c179c7/81c8e/ioBroker_MQTT_09.png 230w", "/en/static/84fdaeb9e055e1ccae0089da60c179c7/08a84/ioBroker_MQTT_09.png 460w", "/en/static/84fdaeb9e055e1ccae0089da60c179c7/c0255/ioBroker_MQTT_09.png 920w", "/en/static/84fdaeb9e055e1ccae0089da60c179c7/a3a5c/ioBroker_MQTT_09.png 1277w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/84fdaeb9e055e1ccae0089da60c179c7/c0255/ioBroker_MQTT_09.png",
              "alt": "ioBroker v9.1.2 as MQTTv5 Client for your  WQHD INSTAR IP Camera",
              "title": "ioBroker v9.1.2 as MQTTv5 Client for your  WQHD INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Make sure that both `}<strong parentName="p">{`subscribe`}</strong>{` and `}<strong parentName="p">{`publish`}</strong>{` are activated:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8ba1e87f7ca87b1bad2ef258581984b2/3f20e/ioBroker_MQTT_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "42.173913043478265%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAABqUlEQVQozy3M227aQBRGYT9RVTWFYGzGxzl4bI8xPhAgIWlDmjRtlL7/zaowXHxa0t7S782+3HJXt3SmZtf03F2NbsPoOvrz76rSFiuLSXFlznJDaUpmN3M8NQ94HB8Ymy2bwrFWBY2yOF3gtKXWdhoqtaGQGiPVROcXKlfITGKNJVgEeFWs+fz7zvF55P6po3+scA8FtlcUg5zoQaLOHdWVnqpGhRwVeZ9j9xZ/6eO5csehrunKisO4Z+dqOiNxMsWpjEommDwmjpasVgti4ZOJYJKKJWm0JEkilJYsZgs8OQ8ZVI0TikZIutyx332w6d9wmxdse0K2z4jyntAeJlH5QFY/klZHkupI6EckQczttxmevBVsizVNomkTRatb+uE31fqE7V7JmyNhuSddPxE3R/LNz6miOpC1P4jqe3x/RRQKZvMQTwcp43kwNZdB09Hv/1EMf1DbD7L+lag9kQ1vxN0v8u076fBGtHm5tD3h+wIRrPg+W+JZP2EwDhcrKpFh4gJjetLUIvWaJK8JY0OmGqKsIjctsbzcEuUIIs1ivmQVCG6+3vAffCkqX9XhWwUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8ba1e87f7ca87b1bad2ef258581984b2/e4706/ioBroker_MQTT_10.avif 230w", "/en/static/8ba1e87f7ca87b1bad2ef258581984b2/d1af7/ioBroker_MQTT_10.avif 460w", "/en/static/8ba1e87f7ca87b1bad2ef258581984b2/7f308/ioBroker_MQTT_10.avif 920w", "/en/static/8ba1e87f7ca87b1bad2ef258581984b2/441b2/ioBroker_MQTT_10.avif 1275w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8ba1e87f7ca87b1bad2ef258581984b2/a0b58/ioBroker_MQTT_10.webp 230w", "/en/static/8ba1e87f7ca87b1bad2ef258581984b2/bc10c/ioBroker_MQTT_10.webp 460w", "/en/static/8ba1e87f7ca87b1bad2ef258581984b2/966d8/ioBroker_MQTT_10.webp 920w", "/en/static/8ba1e87f7ca87b1bad2ef258581984b2/5e05a/ioBroker_MQTT_10.webp 1275w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8ba1e87f7ca87b1bad2ef258581984b2/81c8e/ioBroker_MQTT_10.png 230w", "/en/static/8ba1e87f7ca87b1bad2ef258581984b2/08a84/ioBroker_MQTT_10.png 460w", "/en/static/8ba1e87f7ca87b1bad2ef258581984b2/c0255/ioBroker_MQTT_10.png 920w", "/en/static/8ba1e87f7ca87b1bad2ef258581984b2/3f20e/ioBroker_MQTT_10.png 1275w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8ba1e87f7ca87b1bad2ef258581984b2/c0255/ioBroker_MQTT_10.png",
              "alt": "ioBroker v9.1.2 as MQTTv5 Client for your  WQHD INSTAR IP Camera",
              "title": "ioBroker v9.1.2 as MQTTv5 Client for your  WQHD INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Repeat the last step for the `}<strong parentName="p">{`RAW Command Topic`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2c4eacc372a816afaaca24dfd6de4e7c/7960f/ioBroker_MQTT_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "43.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB5klEQVQozzXO207bQBCAYT9PgRCOgSTOOt6DvT7u2o5DaIBAKQVUVPWmr/9XcenFpxnNxa8JxJWgSXJqbelLT1e4QZvXNFmFyyrqrBxUtiCVySCRZmA+JTpFhBFBZ3J2/T23/it97ulsRWtr2qyizfbRGv8/bEtybQfZJ6stqU7/TZUSbErH2/t3truOm63D3VmKTULqFWkjSRqJaSSqidGtxHQK0yr0J9UqZCPRK43KFcHG3XLXOPrWs133rH1JU1rqIqEqEsrCYHONziRLI5BJhE7iT0vUIEZbhVKSwMkMHxnqKKOJM9am4rbe4pKGKmnIjUebmijOmYaaWWhYLFKWImMpLJGwLKZLwsmcaBYRuDilNQUuTnBC4rKe/u4P5foXxeY3yfonof9O3L8Plqs3ou4V0bwg+3fkzQfzOGd2NWUxEwRe5bR6H7S4SOPtim79Qda+ottXYv/EotoRN88I94Re/UCtXhD1I8vmG8I/M41Sri8nhGFM0JqSPS8tdaQok4aye0GXW4zfIesHRH6Lcjvi+h7lH4nrB8Jsg3T3iPKOmTDDh+FcEBRzSXItiM+nzE8umRxfMBmNOTs45OJoxPnhiPGXA86ORpweHnE+Ouby+GTY97eTgwPGh8eMR6dcnk/4C8iITS70vRAbAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2c4eacc372a816afaaca24dfd6de4e7c/e4706/ioBroker_MQTT_11.avif 230w", "/en/static/2c4eacc372a816afaaca24dfd6de4e7c/d1af7/ioBroker_MQTT_11.avif 460w", "/en/static/2c4eacc372a816afaaca24dfd6de4e7c/7f308/ioBroker_MQTT_11.avif 920w", "/en/static/2c4eacc372a816afaaca24dfd6de4e7c/0501e/ioBroker_MQTT_11.avif 1274w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2c4eacc372a816afaaca24dfd6de4e7c/a0b58/ioBroker_MQTT_11.webp 230w", "/en/static/2c4eacc372a816afaaca24dfd6de4e7c/bc10c/ioBroker_MQTT_11.webp 460w", "/en/static/2c4eacc372a816afaaca24dfd6de4e7c/966d8/ioBroker_MQTT_11.webp 920w", "/en/static/2c4eacc372a816afaaca24dfd6de4e7c/15a27/ioBroker_MQTT_11.webp 1274w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2c4eacc372a816afaaca24dfd6de4e7c/81c8e/ioBroker_MQTT_11.png 230w", "/en/static/2c4eacc372a816afaaca24dfd6de4e7c/08a84/ioBroker_MQTT_11.png 460w", "/en/static/2c4eacc372a816afaaca24dfd6de4e7c/c0255/ioBroker_MQTT_11.png 920w", "/en/static/2c4eacc372a816afaaca24dfd6de4e7c/7960f/ioBroker_MQTT_11.png 1274w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2c4eacc372a816afaaca24dfd6de4e7c/c0255/ioBroker_MQTT_11.png",
              "alt": "ioBroker v9.1.2 as MQTTv5 Client for your  WQHD INSTAR IP Camera",
              "title": "ioBroker v9.1.2 as MQTTv5 Client for your  WQHD INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on the value of the `}<strong parentName="p">{`RAW Command Topic`}</strong>{` to try updating it. Currently the privacy area is activated - so sending a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`0`}</code>{` should deactivate it:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5eb1d081739d566f6b98de0d824a31b6/7960f/ioBroker_MQTT_12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.391304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACOklEQVQoz2XSyWsUQRTH8b5qTByrp6u6tt5mMktPNkWNKC4RxJPRLNOTTBITVxSD4MGrIOT//kpXBBUPH169y+9VUS/qSUcRp7hOQk9n9F1FaQp6rqDQnkw5cu3xymKlwUr9uxqM1BhpcNrhUoc3nmjV5gwTTdlJmGTLjFxJpTx9U1AXQ0rtQ3CeuqANzlIXtKGZ9lhlQ5g3GdH9JwfceXTA2s3nrFc1a8UyfeVY1jmTckSuDF4atNRBG+KlxQWXN9WJpnBFOEfr47tMBrcZ5jVjU7JajBibnFykOCGxQpHc6CI6cRDfiJEiIRWKVEiUkCGwdCVOOaKBclRC0otTtm5t8uzuQ7ZubnKv3uBOvc76aIV6WDP+S9tPRhNWRiuhqriLUxrdlUSTfEBPekam5Nv7c75/POfz4RlvmxOOpnNm00Oa6cH/9tt6yHRvRrn6gGJjCzfeJNoox1SJY2QKvpx+5MvZJz4cv+V0fsbx/IxZc0wzndNMj5j+Y860OWJ//4CsqvHVBJ0NiGqVU3YNfel40xzxbv6a+eyEvZ0Z+9u77G7vsrO9E+y+eMWLlw1PD7/xuPnKjx8XXPy8ILMenShU+2S7KLDXBOmioDIZvazPcjkg01nYrUD/YbVHmxytc4qsoswqZDfFaU+aaCK10KF7dYl4oYMRCpOY8GO6m16SGikkMv6LSIJEJOiwUgZnc7SyRPGVJcSVRcTCdWw7SRpKm4dlDdvfTu6mYfqlNFBtlRpvs9A7cxn4CyEBRKMkkamcAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5eb1d081739d566f6b98de0d824a31b6/e4706/ioBroker_MQTT_12.avif 230w", "/en/static/5eb1d081739d566f6b98de0d824a31b6/d1af7/ioBroker_MQTT_12.avif 460w", "/en/static/5eb1d081739d566f6b98de0d824a31b6/7f308/ioBroker_MQTT_12.avif 920w", "/en/static/5eb1d081739d566f6b98de0d824a31b6/0501e/ioBroker_MQTT_12.avif 1274w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5eb1d081739d566f6b98de0d824a31b6/a0b58/ioBroker_MQTT_12.webp 230w", "/en/static/5eb1d081739d566f6b98de0d824a31b6/bc10c/ioBroker_MQTT_12.webp 460w", "/en/static/5eb1d081739d566f6b98de0d824a31b6/966d8/ioBroker_MQTT_12.webp 920w", "/en/static/5eb1d081739d566f6b98de0d824a31b6/15a27/ioBroker_MQTT_12.webp 1274w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5eb1d081739d566f6b98de0d824a31b6/81c8e/ioBroker_MQTT_12.png 230w", "/en/static/5eb1d081739d566f6b98de0d824a31b6/08a84/ioBroker_MQTT_12.png 460w", "/en/static/5eb1d081739d566f6b98de0d824a31b6/c0255/ioBroker_MQTT_12.png 920w", "/en/static/5eb1d081739d566f6b98de0d824a31b6/7960f/ioBroker_MQTT_12.png 1274w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5eb1d081739d566f6b98de0d824a31b6/c0255/ioBroker_MQTT_12.png",
              "alt": "ioBroker v9.1.2 as MQTTv5 Client for your  WQHD INSTAR IP Camera",
              "title": "ioBroker v9.1.2 as MQTTv5 Client for your  WQHD INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`A few seconds later the `}<strong parentName="p">{`Status Topic`}</strong>{` will update accordingly with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"0"}`}</code>{` and the area will disappear in the camera livestream:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fb73cd33aa794faa6209ae435fcbd601/0d98f/ioBroker_MQTT_13.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.69565217391304%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAACb0lEQVQoz23Sa3PTRhTGcX2DDi0FQoyR7MSWVlqttLu6Ok5i1wm5mDQmpKXpDNN2yLSFcBnS8pLhmzB80n9HMtehL37znN0XO7t7jtNft7jfryC8AYfTXfa2Z8xne2zmNeO8ZpRV1FlJ3WbFKF9m1cgryqykyMqP6ZhBRNjtE3Q8qqRgZGqsKqhMhYk1JkrIE0MaKeIgWhKyJf0IGUi00qRxikkszlhI1Ootoo7HTjVh21bYMGWsaw629imUJlcGG+tWGsYYqbHKoETcZipTjDKUtsT55dVbfrx8x/Tkkt1ywm4xJvMlozjnzmiHZBii/IhwKFrNrZQvif2o1azFekCh8/ZwZ/fwN0Y/nJHbGZNsk/n2AVNbYwNF6ku0SIgCSeBHS0HzzBglVCsWisiXFKYkS3Mc7a3hX1shunmLTRExsxscFjV5fx3TX0f2egzd218JXBfheQjPZdDtooUgFSHOSBVE3T6pyLn78DVHD19z+scbpvdfMn3wD1sPXmKOL7D3nmIXX8oWF9SLC8zxEzbO/qX+6QXOdlohOj2SgeLO0Tnz+xfsHf1ONj3FTE7QkxOS/6E+S7m1oNr/lXznFGdjGCO6a4iORzbwyeKcOtEIr0/Q6PXxvUbvkw97vWU9cF0SEaICgdO7epPe1RXWbnRJfInRG+3gRsMQMRBLTT0UBB+F7/fCth6u+ejEksYax/1uhdUr13Cvd0iCGK0KxvWk7Z5shAmiGQ1ffubTWkmNjBJ0mpM2XV799jo3vrmCe9vHzv8mv/sXs7NL7PxPqubT7z0l3n9EcnDeUm0+QrXO2fz5OfnxY8qTZxSLJ/wHoVqFYfxxIz8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fb73cd33aa794faa6209ae435fcbd601/e4706/ioBroker_MQTT_13.avif 230w", "/en/static/fb73cd33aa794faa6209ae435fcbd601/d1af7/ioBroker_MQTT_13.avif 460w", "/en/static/fb73cd33aa794faa6209ae435fcbd601/7f308/ioBroker_MQTT_13.avif 920w", "/en/static/fb73cd33aa794faa6209ae435fcbd601/8faeb/ioBroker_MQTT_13.avif 1276w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fb73cd33aa794faa6209ae435fcbd601/a0b58/ioBroker_MQTT_13.webp 230w", "/en/static/fb73cd33aa794faa6209ae435fcbd601/bc10c/ioBroker_MQTT_13.webp 460w", "/en/static/fb73cd33aa794faa6209ae435fcbd601/966d8/ioBroker_MQTT_13.webp 920w", "/en/static/fb73cd33aa794faa6209ae435fcbd601/63399/ioBroker_MQTT_13.webp 1276w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fb73cd33aa794faa6209ae435fcbd601/81c8e/ioBroker_MQTT_13.png 230w", "/en/static/fb73cd33aa794faa6209ae435fcbd601/08a84/ioBroker_MQTT_13.png 460w", "/en/static/fb73cd33aa794faa6209ae435fcbd601/c0255/ioBroker_MQTT_13.png 920w", "/en/static/fb73cd33aa794faa6209ae435fcbd601/0d98f/ioBroker_MQTT_13.png 1276w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fb73cd33aa794faa6209ae435fcbd601/c0255/ioBroker_MQTT_13.png",
              "alt": "ioBroker v9.1.2 as MQTTv5 Client for your  WQHD INSTAR IP Camera",
              "title": "ioBroker v9.1.2 as MQTTv5 Client for your  WQHD INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`To test the regular `}<strong parentName="p">{`Command Topic`}</strong>{` update the value to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"1"}`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8e2f3fc338aa6a9cb527ffa155e62794/0d98f/ioBroker_MQTT_14.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.826086956521735%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAACbUlEQVQoz22TW2sbVxRG57UGR56ZMzPnzLnMNZJGUhybkuAGUwyhNPSlbSzrZiEpCWkTnPahIRDIvyilf3eFGamlkD4s1mE/fBzY3/ZUTyB7IU1eMyr6jMo+g6yiMjmlySlM9gX5gcxkOO1wrU2GNRleFaWYnqBWjloXFCqn1AVl6rCJJk8dTmpMnKJjtSdJSSOFihRGGowy2NSi4hSvLxTFScQkGzB2NbV0FInhtJ5QpY5CWZw0ZMrgEn14W0yiO6yy6ERT2IJESLyLpxvOL37iNB9yVg4ZqIwy1ozzAYU0uDgljRUykp1NrLGx7qzj/U/NITDyI7xRfU7fNQxVxsT1GaU5hVCYIMYGCYkfEZyE/yJ8gQzijuSAjiR5aolOBF4VSsog4ersMd9fXPH06yd8Mz7n8eiM8+aU8XBMMxgxGuzd0s4mwwkPmgl1NUSZirxsECLFqxNHnVh+373h07s/+G39kl8WG3bzNavZiuVsyeJ/mM+WrJe3fPfsR/T4kurRMyLX4FWxpY4Nd9tf+XD3ntfrV2xXO26XO+azNdPpkul01XHdecnNdMHPz+dMb1Z8e3mF8INuy37PxysjTSlSttcL3m5fsb19wWK+YXE9Y349Z9Yx2/v5DT8s77hcfGDz9iN///kXu81L/HsBmcnxeyGeOQ5RxwH3Tc79vE9TjyhdSaazPf8U+GBta6QdUFUND8cPqfKaOExwOiNoA6OjHuFRD+VHqFCS6byrQ1sBKWRn8R8iXxD7IeFh60lbp7aftiBsaxN8dUxwdA8tJDpSVK7sytpeQOHKLlRG6gvaoO5CtEPFCmsKhB/zGfI7YW5cyH0kAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8e2f3fc338aa6a9cb527ffa155e62794/e4706/ioBroker_MQTT_14.avif 230w", "/en/static/8e2f3fc338aa6a9cb527ffa155e62794/d1af7/ioBroker_MQTT_14.avif 460w", "/en/static/8e2f3fc338aa6a9cb527ffa155e62794/7f308/ioBroker_MQTT_14.avif 920w", "/en/static/8e2f3fc338aa6a9cb527ffa155e62794/8faeb/ioBroker_MQTT_14.avif 1276w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8e2f3fc338aa6a9cb527ffa155e62794/a0b58/ioBroker_MQTT_14.webp 230w", "/en/static/8e2f3fc338aa6a9cb527ffa155e62794/bc10c/ioBroker_MQTT_14.webp 460w", "/en/static/8e2f3fc338aa6a9cb527ffa155e62794/966d8/ioBroker_MQTT_14.webp 920w", "/en/static/8e2f3fc338aa6a9cb527ffa155e62794/63399/ioBroker_MQTT_14.webp 1276w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8e2f3fc338aa6a9cb527ffa155e62794/81c8e/ioBroker_MQTT_14.png 230w", "/en/static/8e2f3fc338aa6a9cb527ffa155e62794/08a84/ioBroker_MQTT_14.png 460w", "/en/static/8e2f3fc338aa6a9cb527ffa155e62794/c0255/ioBroker_MQTT_14.png 920w", "/en/static/8e2f3fc338aa6a9cb527ffa155e62794/0d98f/ioBroker_MQTT_14.png 1276w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8e2f3fc338aa6a9cb527ffa155e62794/c0255/ioBroker_MQTT_14.png",
              "alt": "ioBroker v9.1.2 as MQTTv5 Client for your  WQHD INSTAR IP Camera",
              "title": "ioBroker v9.1.2 as MQTTv5 Client for your  WQHD INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And the `}<strong parentName="p">{`Status Topic`}</strong>{` should update accordingly:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cb0c9b8bb9b7b53c74ad82efad795023/9cea8/ioBroker_MQTT_15.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.826086956521735%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAACeUlEQVQoz3XTy24cRRSA4X4FLpYTjy9j97W6urqqL9W3Gds9npmMx+DgLJJYiUBxjCBiE7EBKVFAiAUsWPEcvOOPpmNFLMLi0784tSgdVTnuzgHu/T2Wk551v2A9WzLvTpiWDZOyoSsb2rIedLahtQ1NWdPYhrqsqYrqfe84mSsI7u9Tq4I277C6pik6msxiYk1tSnJl0EKhIjnQsSKJJDKUZGlOnubYzJLECqcYu4jtEWd1z7I5oVUFVhquFle0xlLpgkJlWJ2TJ2ZgdUGWmEGhC7TUTJspkRfhPHn3D+ev/qa3PevpnIkqKKKU1eQBpTQYsblNQhxJEpGgRYqO0qHpZhZKTGKY1BPcAxdncvKIIp9znOSs2hmLzU42h7yAPBAkfkjk+YQDj9gLUH5E6kdDpR+io5g2L/H2D3HUaB+xvUvfrVg9uObq4hkn3Yq2XlJWZ6S2JylPScq72lOU7dF2NsxUeYpp5kzOHuIFKY5xBXIccfHNL1ze/sH1qz/54uZ3+q9/ZXn7G9WzN//jLd31z7RPf6J5/obFD38Rdg9x0qOIePeIqTTM7DHLqiULY2LXR4UC4XpEA/8/vA/CIxcZhFSF5WB3Hyc9DIl2xliRYrMJ/XSOkZo4kMThRnJXiRgkdyQiet9EptS2ZbzZobc14mhrROpFGGU5bmeYWBH7AuEL4kAgPiIO4qGhF5IINTz08d4hzt6n2+x+dg8VSLTMmXYzqqxCRgqT5kPjjxCRQm3e5eYDpDlt23M49nF2Pvmc0dYO2fw5xfpbFk9f01x+R3X5Pf2T16SrG8z6Fn3+8gNz/gJ9foP+8gX5Vy/JL27pHv+In5/xL4jRg28wH0+eAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cb0c9b8bb9b7b53c74ad82efad795023/e4706/ioBroker_MQTT_15.avif 230w", "/en/static/cb0c9b8bb9b7b53c74ad82efad795023/d1af7/ioBroker_MQTT_15.avif 460w", "/en/static/cb0c9b8bb9b7b53c74ad82efad795023/7f308/ioBroker_MQTT_15.avif 920w", "/en/static/cb0c9b8bb9b7b53c74ad82efad795023/39beb/ioBroker_MQTT_15.avif 1278w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cb0c9b8bb9b7b53c74ad82efad795023/a0b58/ioBroker_MQTT_15.webp 230w", "/en/static/cb0c9b8bb9b7b53c74ad82efad795023/bc10c/ioBroker_MQTT_15.webp 460w", "/en/static/cb0c9b8bb9b7b53c74ad82efad795023/966d8/ioBroker_MQTT_15.webp 920w", "/en/static/cb0c9b8bb9b7b53c74ad82efad795023/893d6/ioBroker_MQTT_15.webp 1278w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cb0c9b8bb9b7b53c74ad82efad795023/81c8e/ioBroker_MQTT_15.png 230w", "/en/static/cb0c9b8bb9b7b53c74ad82efad795023/08a84/ioBroker_MQTT_15.png 460w", "/en/static/cb0c9b8bb9b7b53c74ad82efad795023/c0255/ioBroker_MQTT_15.png 920w", "/en/static/cb0c9b8bb9b7b53c74ad82efad795023/9cea8/ioBroker_MQTT_15.png 1278w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cb0c9b8bb9b7b53c74ad82efad795023/c0255/ioBroker_MQTT_15.png",
              "alt": "ioBroker v9.1.2 as MQTTv5 Client for your  WQHD INSTAR IP Camera",
              "title": "ioBroker v9.1.2 as MQTTv5 Client for your  WQHD INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now you can use the command topics for your ioBroker automations!`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      